import * as React from "react";

const DeliveryIcon = ({ size = 18, color = "#606060", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20.144 16"
      style={style}
    >
      <path
        id="icons8-trailer-unloading"
        d="M9.42,1.3a.63.63,0,0,0-.335.111L7.747,2.3.289,7.265a.63.63,0,0,0-.177.873L2.517,11.75v2.4a1.9,1.9,0,0,0,1.889,1.889h.748a1.875,1.875,0,0,0,3.542,0h3.166a.63.63,0,0,0,.2,0h1.872a1.875,1.875,0,0,0,3.542,0h.78a1.9,1.9,0,0,0,1.889-1.889V12.01a1.887,1.887,0,0,0-.325-1.06L17.86,8.05A1.889,1.889,0,0,0,16.3,7.221H13.223A1.9,1.9,0,0,0,11.334,9.11V11H10.055l-1.4-2.086L11.078,7.3a.63.63,0,0,0,.175-.873L8.97,3l.812-.541A.63.63,0,0,0,9.42,1.3Zm-1.5,2.4L9.856,6.6,3.445,10.869,1.511,7.964Zm5.3,4.785H16.3a.629.629,0,0,1,.522.276l1.966,2.9a.629.629,0,0,1,.108.352v2.138a.621.621,0,0,1-.63.63h-.78a1.875,1.875,0,0,0-3.542,0H12.594V9.11A.621.621,0,0,1,13.223,8.48ZM7.607,9.612,8.537,11H5.521Zm-3.83,2.647h7.557v2.519H8.7a1.875,1.875,0,0,0-3.542,0H4.407a.621.621,0,0,1-.63-.63Zm3.149,2.519a.63.63,0,1,1-.63.63A.62.62,0,0,1,6.926,14.778Zm8.785,0a.63.63,0,1,1-.63.63A.62.62,0,0,1,15.71,14.778Z"
        transform="translate(-0.006 -1.297)"
        fill={color}
      />
    </svg>
  );
};

export default DeliveryIcon;
