import React, { useContext, useMemo } from "react";
import { Box } from "@mui/material";
import { ItemContent, Title } from "./styles";
import { ParkingContext } from "../../../services/parking/parkingContext";

const PriceListPage = () => {
  const { organizationInfo } = useContext(ParkingContext);

  const content = useMemo(() => {
    if (!organizationInfo) return;
    switch (organizationInfo?.name) {
      case "zaspa":
        return (
          <ItemContent>
            <b>Pierwsze 1,5 godziny BEZPŁATNE*</b>
            <br />
            (*Raz dziennie dla danego numeru rejestracyjnego i pod warunkiem
            wpisania numeru rejestracyjnego do Parkometru lub zarejestrowania
            się w Aplikacji Mobilnej obsługującej parking od chwili zaparkowania
            pojazdu na terenie parkingu)
            <br />
            UWAGA! Bilet zerowy z błędnym numerem rejestracyjnym jest nieważny.
            <br />
            <br />
            <b>Koszt parkowania po upływie BEZPŁATNEJ 1,5 godziny:</b>
            <br /> 1-sza rozpoczęta godzina 4,50 PLN
            <br /> 2-ga rozpoczęta godzina 4,50 PLN
            <br /> 3-cia i każda następna rozpoczęta godzina 6,00 PLN
            <br />
            <br />
            Opłata Dodatkowa 190,00 PLN - zgodnie z Regulaminem.
            <br />
            (W przypadku płatności do 3 dni Opłata Dodatkowa wynosi 120,00 PLN)
          </ItemContent>
        );
      default:
        return (
          <ItemContent>
            Pierwsze 3 Godziny GRATIS* w godzinach otwarcia Centrum: 07:00 -
            22:00
            <br />
            *Raz dziennie dla danego numeru rejestracyjnego i pod warunkiem
            wpisania nr rejestracyjnego do Parkometru lub zarejestrowania się w
            Aplikacji Mobilnej obsługującej parking od chwili zaparkowania
            pojazdu na terenie parkingu)
            <br /> Każda rozpoczęta godzina parkowania 4,00 PLN
            <br /> Opłata Dodatkowa 150,00 PLN - zgodnie z Regulaminem.
            <br /> (W przypadku płatności do 3 dni Opłata Dodatkowa wynosi 95,00
            PLN)
          </ItemContent>
        );
    }
  }, [organizationInfo]);

  return (
    <Box>
      <Title data-testid="pricePageTitle">Cennik parkingu</Title>
      {content}
    </Box>
  );
};

export default PriceListPage;
