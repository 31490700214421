import AttributesList from "./AttributesList";
import AttributesCreate from "./AttributesCreate";
import AttributesEdit from "./AttributesEdit";

const reservationAttributesResource = {
  list: AttributesList,
  create: AttributesCreate,
  edit: AttributesEdit,
};

export default reservationAttributesResource;
