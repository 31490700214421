import { TParkingSettings } from "../hooks/useParkingSettings";
import { THelpdeskSettings } from "../hooks/useHelpdeskSettings";
import { TDeliverySettings } from "../hooks/useDeliverySettings";

export class SettingsManager {
  private static instance: SettingsManager;

  private helpdeskSettings: THelpdeskSettings | undefined;
  private parkingSettings: TParkingSettings | undefined;
  private deliverySettings: TDeliverySettings | undefined;

  public static getInstance(): SettingsManager {
    if (!SettingsManager.instance) {
      SettingsManager.instance = new SettingsManager();
    }

    return SettingsManager.instance;
  }

  public getHelpdeskSettings(): THelpdeskSettings | undefined {
    return this.helpdeskSettings;
  }

  public setHelpdeskSettings(settings: THelpdeskSettings) {
    this.helpdeskSettings = settings;
  }

  public getDeliverySettings(): TDeliverySettings | undefined {
    return this.deliverySettings;
  }

  public setDeliverySettings(settings: TDeliverySettings) {
    this.deliverySettings = settings;
  }

  public getParkingSettings(): TParkingSettings | undefined {
    return this.parkingSettings;
  }

  public setParkingSettings(settings: TParkingSettings) {
    this.parkingSettings = settings;
  }
}
