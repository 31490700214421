import { ReactElement, useEffect, useState } from "react";
import { Box, CircularProgress, styled } from "@mui/material";
import {
  ReferenceInput,
  TextInput,
  useGetIdentity,
  useLocaleState,
  usePermissions,
} from "react-admin";
import { useFormContext } from "react-hook-form";
import { ITicketAttribute } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { helpdeskAttributesService } from "../../../../api/HelpdeskAttributesService";
import { IBasicResponse } from "../../../../types/interfaces/response/basic-response.interface";
import AttributesInputArray from "../../../../components/resources/helpdesk/tickets/AttributesInputArray";
import { ConfigManager } from "../../../../constants/ConfigManager";
import { Roles } from "../../../../types/enums/roles";
import { StyledAutocompleteInput } from "../../../../themes/styles";
import { IIdentity } from "../../../../types/interfaces/identity.interface";

const LoaderContainer = styled(Box)(() => ({
  textAlign: "center",
  width: "100%",
}));

const FormInnerContainer = styled(Box)(() => ({
  width: "80%",
  maxWidth: "500px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const CreateTicketHandler = (): ReactElement => {
  const { permissions, isLoading: permissionsLoading } = usePermissions();
  const [currentLocale] = useLocaleState();
  const { setValue } = useFormContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [attributes, setAttributes] = useState<ITicketAttribute[]>([]);
  const { isLoading: userLoading, data: userData } =
    useGetIdentity() as unknown as {
      isLoading: boolean;
      data: IIdentity;
    };

  const fetchTicketAttributes = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { data } =
        await helpdeskAttributesService.getAttributes<
          IBasicResponse<ITicketAttribute[]>
        >();

      data.sort(
        (a: ITicketAttribute, b: ITicketAttribute) => a.position - b.position,
      );

      setAttributes(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTicketAttributes();

    if (!userLoading && userData) {
      setValue("payload.userId", userData.id.toString());
    }
  }, [setValue, userData, userLoading]);

  if (userLoading) {
    return (
      <LoaderContainer>
        <CircularProgress size={100} />
      </LoaderContainer>
    );
  }

  return (
    <div>
      <FormInnerContainer>
        {isLoading ? (
          <LoaderContainer>
            <CircularProgress size={100} />
          </LoaderContainer>
        ) : (
          <>
            <TextInput
              source="payload.groupId"
              hidden
              sx={{ display: "none" }}
              defaultValue={ConfigManager.getInstance().getOrgId()}
            />
            <TextInput
              source="payload.userId"
              hidden
              sx={{ display: "none" }}
            />
            <AttributesInputArray
              inputsData={attributes}
              dataPrefix="payload"
            />
            {!permissionsLoading &&
              permissions.includes(Roles.ROLE_MODULE_HELPDESK_ADMIN) && (
                <>
                  <ReferenceInput
                    source="assigneeId"
                    reference="admin/organization/users"
                    filter={{ roles: Roles.ROLE_MODULE_HELPDESK_ASSIGNEE }}
                    sort={{ field: "name", order: "ASC" }}
                  >
                    <StyledAutocompleteInput
                      optionText={(record) =>
                        `${record.firstName} ${record.lastName}`
                      }
                      optionValue="id"
                      label="app.helpdesk.assign_user"
                      variant="outlined"
                      filterToQuery={(searchText: string) => ({
                        search: searchText,
                      })}
                      sx={{ minWidth: 210 }}
                    />
                  </ReferenceInput>
                  <ReferenceInput
                    source="workflowId"
                    reference="helpdesk/admin/organization/workflows"
                    filter={{ active: "all" }}
                  >
                    <StyledAutocompleteInput
                      optionText={(record) =>
                        record.name[currentLocale] || record.name["en"]
                      }
                      optionValue="id"
                      label="app.helpdesk.assign_workflow"
                      variant="outlined"
                      filterToQuery={(searchText: string) => ({
                        search: searchText,
                      })}
                      sx={{ minWidth: 210 }}
                    />
                  </ReferenceInput>
                </>
              )}
          </>
        )}
      </FormInnerContainer>
    </div>
  );
};

export default CreateTicketHandler;
