import React, { useMemo } from "react";
import { listTopFilter, StyledList } from "../../../../../themes/styles";
import {
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  RichTextField,
  TextField,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from "react-admin";
import CustomPagination from "../../../../common/CustomPagination";
import { ITicketHistoryItem } from "../../../../../types/interfaces/helpdesk/ticket/ticket-history-item.interface";
import {
  EResource,
  getResourceUrl,
} from "../../../../../utils/resourcesHelper";

const TicketHistoryPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const TicketHistoryField = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const attrUrl = getResourceUrl(EResource.ATTRIBUTES);

  if (record.field === "assignee") {
    return <span>{translate("app.helpdesk.assigned_user")}</span>;
  }

  return (
    <ReferenceField
      reference={attrUrl}
      source="entityId"
      label="app.helpdesk.field"
    >
      <TextField source={`name.${locale}`} />
    </ReferenceField>
  );
};

const TicketHistory = ({ ticketId }: { ticketId: string | undefined }) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();

  const getHistoryUrl = useMemo(() => {
    return getResourceUrl(EResource.TICKETS_HISTORY, { ticketId });
  }, [ticketId]);

  if (!ticketId) {
    return <></>;
  }

  return (
    <StyledList
      resource={getHistoryUrl}
      pagination={<TicketHistoryPagination />}
      actions={false}
      className="styled-list styled-list--inner"
      empty={<b>{translate("app.helpdesk.empty_ticket_history")}</b>}
    >
      <Datagrid bulkActionButtons={false}>
        <TicketHistoryField />

        <FunctionField
          abel="app.helpdesk.action"
          render={(record: ITicketHistoryItem) => {
            return <span>{translate(`app.helpdesk.${record.action}`)}</span>;
          }}
        />
        <DateField
          source="createdAt"
          label="app.helpdesk.date_of_change"
          showTime={true}
        />
        <FunctionField
          label="app.helpdesk.before"
          render={(record: ITicketHistoryItem) => {
            if (typeof record.before === "string") {
              return (
                <RichTextField
                  record={record}
                  source="before"
                  label="app.helpdesk.before"
                />
              );
            }

            if (record.before) {
              return `${record.before[locale as keyof typeof record.before]}`;
            }

            return <></>;
          }}
        />
        <FunctionField
          label="app.helpdesk.after"
          render={(record: ITicketHistoryItem) => {
            if (typeof record.after === "string") {
              return (
                <RichTextField
                  record={record}
                  source="after"
                  label="app.helpdesk.before"
                />
              );
            }

            if (record.after) {
              return `${record.after[locale as keyof typeof record.after]}`;
            }

            return <></>;
          }}
        />
        <FunctionField
          label="app.helpdesk.user"
          render={(record: ITicketHistoryItem) => {
            if (record.user) {
              return `${record.user.firstName} ${record.user.lastName[0]}`;
            }
          }}
        />
      </Datagrid>
    </StyledList>
  );
};

export default TicketHistory;
