import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { IWorkflowRole } from "../types/interfaces/helpdesk/workflow/workflow-role.interface";
import { httpClient } from "../providers/dataProvider";
import { ConfigManager } from "../constants/ConfigManager";

const useWorkflowRoles = () => {
  const translate = useTranslate();

  const [workflowRoles, setWorkflowRoles] = useState<IWorkflowRole[]>([]);
  const [translatedWorkflowRoles, setTranslatedWorkflowRoles] = useState<
    IWorkflowRole[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchWorkflowRoles = async () => {
    const { json } = await httpClient(
      `${ConfigManager.getInstance().getApiAdminUrl()}/helpdesk/admin/organization/workflow-roles`,
      {
        method: "GET",
      },
    );

    return json.data;
  };

  const assignWorkflowRoles = async () => {
    try {
      setIsLoading(true);
      const roles = await fetchWorkflowRoles();
      setWorkflowRoles(roles);
      setTranslatedWorkflowRoles(
        roles?.map((role: IWorkflowRole) => {
          return {
            ...role,
            name: translate(`app.helpdesk.${role.name.toLowerCase()}`),
          };
        }),
      );
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    assignWorkflowRoles();
  }, []);

  return { isLoading, workflowRoles, translatedWorkflowRoles };
};

export default useWorkflowRoles;
