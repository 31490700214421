import { useWatch } from "react-hook-form";
import { ReactElement, useEffect, useState } from "react";

export interface IHandlerComponentProps {
  readonly handlersMap: Record<string, ReactElement>;
}

const HandlerComponent = ({
  handlersMap,
}: IHandlerComponentProps): ReactElement => {
  const [handler, setHandler] = useState<string>("");
  const handlerWatch = useWatch<{ handler: string }>({
    name: "handler",
  });

  useEffect(() => {
    setHandler(handlerWatch);
  }, [handlerWatch]);

  if (!handler || !handlersMap[handler]) {
    return <></>;
  }

  return handlersMap[handler];
};

export default HandlerComponent;
