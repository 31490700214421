import React from "react";
import {
  CreateButton,
  Datagrid,
  FunctionField,
  ListToolbarProps,
  TextField,
  TopToolbar,
  useAuthenticated,
  useLocaleState,
  useTranslate,
} from "react-admin";
import { sanitizeListRestProps } from "ra-core";
import { Chip, Stack } from "@mui/material";
import { listTopFilter, StyledList } from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";
import { IWorkflow } from "../../../types/interfaces/helpdesk/workflow/workflow.interface";

const WorkflowsListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const WorkflowsListView = () => {
  const [locale] = useLocaleState();
  const translate = useTranslate();

  return (
    <SkeletonLoaderList>
      <Datagrid bulkActionButtons={false} rowClick="edit">
        <TextField source={`name.${locale}`} label="app.helpdesk.name" />
        <TextField
          source={`description.${locale}`}
          label="app.helpdesk.description"
        />
        <FunctionField
          label="Status"
          render={(record: IWorkflow): React.ReactNode => (
            <span>{translate(`app.helpdesk.workflow.${record.active}`)}</span>
          )}
        />
        <FunctionField
          label="app.helpdesk.roles"
          render={(record: IWorkflow): React.ReactNode => {
            return (
              <Stack direction="row" spacing={1}>
                {record.roles.map((item: string) => (
                  <Chip label={translate(`app.helpdesk.${item}`)} />
                ))}
              </Stack>
            );
          }}
        />
      </Datagrid>
    </SkeletonLoaderList>
  );
};

const WorkflowsPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const WorkflowsList = () => {
  useAuthenticated();

  return (
    <StyledList
      pagination={<WorkflowsPagination />}
      actions={<WorkflowsListTopToolbar />}
      empty={false}
      className="styled-list"
    >
      <WorkflowsListView />
    </StyledList>
  );
};

export default WorkflowsList;
