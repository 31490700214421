import React from "react";
import { useLocaleState } from "react-admin";
import { ITicketAttribute } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute.interface";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import AttributeInput from "./AttributeInput";

type AttributesFieldArrayType = {
  inputsData: ITicketAttribute[];
  dataPrefix?: string;
};

const FormInputWrapper = styled(Box)(() => ({
  width: "100%",
  margin: "10px 0",
}));

const AttributesInputArray = ({
  inputsData,
  dataPrefix,
}: AttributesFieldArrayType) => {
  const [locale] = useLocaleState();

  if (dataPrefix) {
    dataPrefix += ".";
  } else {
    dataPrefix = "";
  }

  return (
    <>
      {inputsData.map((item: ITicketAttribute, index: number) => {
        return (
          <FormInputWrapper key={dataPrefix + `attribute${index}`}>
            <AttributeInput
              id={item.id}
              source={`${dataPrefix}attributes[${index}]`}
              inputType={item.type}
              label={item.name[locale as keyof typeof item.name]}
              choices={item?.options}
              isRequired={!item.isOptional}
              isSavingNewValues={item?.isSavingNewValues}
            />
          </FormInputWrapper>
        );
      })}
    </>
  );
};

export default AttributesInputArray;
