import { useTranslate } from "ra-core";
import { ETaskType } from "../../../../types/enums/schedule.enums";

export interface IUseTaskTypeResource {
  readonly taskTypes: { id: ETaskType; name: string }[];
}

const useTaskType = (): IUseTaskTypeResource => {
  const translate = useTranslate();

  const taskTypes = [
    {
      id: ETaskType.OneTime,
      name:
        translate(`app.schedule.taskTypes.${ETaskType.OneTime}`) || "OneTime",
    },
    {
      id: ETaskType.Recurring,
      name:
        translate(`app.schedule.taskTypes.${ETaskType.Recurring}`) ||
        "Recurring",
    },
  ];

  return { taskTypes };
};

export default useTaskType;
