import { useFormContext, useWatch } from "react-hook-form";
import cronParser, { CronFields } from "cron-parser";
import {
  DateTimeInput,
  required,
  TextInput,
  TimeInput,
  useTranslate,
} from "react-admin";
import { ReactElement, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import momentTimezone from "moment-timezone";
import _ from "lodash";
import { ETaskType } from "../../../types/enums/schedule.enums";
import { StyledAutocompleteInput, StyledSelect } from "../../../themes/styles";

const OneTimePlanner = (): ReactElement => {
  const translate = useTranslate();
  const [minDate, setMinDate] = useState(
    moment().add(5, "minutes").startOf("minute").format("YYYY-MM-DDTHH:mm:ss"),
  );
  const minDateInterval = useRef<any | null>(null);

  const timezonesChoices = useMemo(
    () =>
      momentTimezone.tz
        .names()
        .filter((tz) => !tz.startsWith("Etc") && tz.includes("/"))
        .map((name) => ({
          id: name,
          name,
        })),
    [],
  );

  useEffect(() => {
    minDateInterval.current = setInterval(() => {
      setMinDate(
        moment()
          .add(5, "minutes")
          .startOf("minute")
          .format("YYYY-MM-DDTHH:mm:ss"),
      );
    }, 60000);

    return () => {
      clearInterval(minDateInterval.current);
    };
  }, []);

  return (
    <>
      <StyledSelect
        source="timeZone"
        label={translate("app.schedule.form.fields.timeZone")}
        validate={[required()]}
        choices={timezonesChoices}
        defaultValue={momentTimezone.tz.guess()}
        style={{ marginTop: 0, marginRight: "1rem" }}
      />
      <DateTimeInput
        source="oneTime.date"
        label={translate("app.schedule.form.fields.date")}
        validate={[required()]}
        parse={(v): string | null =>
          v ? moment(v).format("YYYY-MM-DDTHH:mm:ss") : null
        }
        inputProps={{
          min: minDate,
        }}
      />
    </>
  );
};

const RecurringPlanner = (): ReactElement => {
  const { setValue } = useFormContext();
  const translate = useTranslate();
  const [cronFields, setCronFields] = useState<CronFields>(
    cronParser.parseExpression("@daily").fields,
  );
  const [every, setEvery] = useState("day");
  const [month, setMonth] = useState(cronFields.month);
  const [dayOfMonth, setDayOfMonth] = useState(cronFields.dayOfMonth);
  const [dayOfWeek, setDayOfWeek] = useState(cronFields.dayOfWeek);
  const [timeZone, setTimeZone] = useState(momentTimezone.tz.guess());
  const [time, setTime] = useState(new Date());

  const timezonesChoices = useMemo(
    () =>
      momentTimezone.tz
        .names()
        .filter((tz) => !tz.startsWith("Etc") && tz.includes("/"))
        .map((name) => ({
          id: name,
          name,
        })),
    [],
  );
  const everyChoices = useMemo(
    () => [
      { id: "day", name: translate("app.schedule.form.fields.day") },
      { id: "week", name: translate("app.schedule.form.fields.week") },
      { id: "month", name: translate("app.schedule.form.fields.month") },
      { id: "year", name: translate("app.schedule.form.fields.year") },
    ],
    [translate],
  );
  const monthChoices = useMemo(
    () => [
      { id: 1, name: translate("app.schedule.form.fields.months.january") },
      { id: 2, name: translate("app.schedule.form.fields.months.february") },
      { id: 3, name: translate("app.schedule.form.fields.months.march") },
      { id: 4, name: translate("app.schedule.form.fields.months.april") },
      { id: 5, name: translate("app.schedule.form.fields.months.may") },
      { id: 6, name: translate("app.schedule.form.fields.months.june") },
      { id: 7, name: translate("app.schedule.form.fields.months.july") },
      { id: 8, name: translate("app.schedule.form.fields.months.august") },
      { id: 9, name: translate("app.schedule.form.fields.months.september") },
      { id: 10, name: translate("app.schedule.form.fields.months.october") },
      { id: 11, name: translate("app.schedule.form.fields.months.november") },
      { id: 12, name: translate("app.schedule.form.fields.months.december") },
    ],
    [translate],
  );
  const dayOfMonthChoices = useMemo(
    () =>
      Array.from({ length: 31 }, (_, i) => ({
        id: i + 1,
        name: i + 1,
      })),
    [],
  );
  const dayOfWeekChoices = useMemo(
    () => [
      { id: 1, name: translate("app.schedule.form.fields.daysOfWeek.monday") },
      { id: 2, name: translate("app.schedule.form.fields.daysOfWeek.tuesday") },
      {
        id: 3,
        name: translate("app.schedule.form.fields.daysOfWeek.wednesday"),
      },
      {
        id: 4,
        name: translate("app.schedule.form.fields.daysOfWeek.thursday"),
      },
      { id: 5, name: translate("app.schedule.form.fields.daysOfWeek.friday") },
      {
        id: 6,
        name: translate("app.schedule.form.fields.daysOfWeek.saturday"),
      },
      { id: 0, name: translate("app.schedule.form.fields.daysOfWeek.sunday") },
    ],
    [translate],
  );

  const cron = useMemo(() => {
    const value = cronParser
      .fieldsToExpression(cronFields, {
        tz: timeZone,
      })
      .stringify();
    setValue("recurring.cron", value);

    return value;
  }, [cronFields, setValue, timeZone]);

  const cronIterates = useMemo(() => {
    return cronParser
      .parseExpression(cron, {
        tz: timeZone,
      })
      .iterate(5)
      .map((date) => date.toString());
  }, [cron, timeZone]);

  const cronFieldsEqual = (a: CronFields, b: CronFields) => {
    return (
      _.isEqual(a.dayOfMonth, b.dayOfMonth) &&
      _.isEqual(a.dayOfWeek, b.dayOfWeek) &&
      _.isEqual(a.hour, b.hour) &&
      _.isEqual(a.minute, b.minute) &&
      _.isEqual(a.month, b.month) &&
      _.isEqual(a.second, b.second)
    );
  };

  const updateCronFields = (prev: CronFields, update: any) => {
    const dayOfMonth = Array.isArray(update.dayOfMonth)
      ? update.dayOfMonth.length === 0
        ? dayOfMonthChoices.map((c) => c.id)
        : update.dayOfMonth
      : prev.dayOfMonth;
    const dayOfWeek = Array.isArray(update.dayOfWeek)
      ? update.dayOfWeek.length === 0
        ? dayOfWeekChoices.map((c) => c.id)
        : update.dayOfWeek
      : prev.dayOfWeek;
    const month = Array.isArray(update.month)
      ? update.month.length === 0
        ? monthChoices.map((c) => c.id)
        : update.month
      : prev.month;
    const hour = Array.isArray(update.hour)
      ? update.hour.length === 0
        ? [time.getHours()]
        : update.hour
      : prev.hour;
    const minute = Array.isArray(update.minute)
      ? update.minute.length === 0
        ? [time.getMinutes()]
        : update.minute
      : prev.minute;
    return {
      ...prev,
      dayOfMonth,
      dayOfWeek,
      month,
      hour,
      minute,
    } as CronFields;
  };

  useEffect(() => {
    const changes = {
      month,
      dayOfMonth,
      dayOfWeek,
      hour: [time.getHours()],
      minute: [time.getMinutes()],
    };

    /* eslint-disable no-fallthrough */
    switch (every) {
      case "minute":
        changes.minute = [];
      case "hour":
        changes.hour = [];
      case "day":
        changes.dayOfMonth = [];
        changes.dayOfWeek = [];
      case "week":
        changes.dayOfMonth = [];
      case "month":
        changes.month = [];
        break;
    }
    /* eslint-enable no-fallthrough */

    const newCronFields = updateCronFields(cronFields, changes);
    const diff = !cronFieldsEqual(cronFields, newCronFields);
    if (diff) {
      setCronFields(newCronFields);
    }
  }, [dayOfMonth, dayOfWeek, every, month, time]);

  const setTimeFromInput = (e: any) => {
    const [hour, minute] = e.target.value.split(":");
    const date = new Date(time);
    date.setHours(Number(hour), Number(minute));
    setTime(date);
  };

  return (
    <>
      <StyledSelect
        source="cron.every"
        choices={everyChoices}
        label={translate("app.schedule.form.fields.every")}
        validate={[required()]}
        defaultValue={every}
        onChange={(e) => setEvery(e.target.value)}
      />

      <Grid container spacing={1}>
        <Grid item xs={12} lg={4} sx={{ maxWidth: "100% !important" }}>
          <StyledAutocompleteInput
            multiple
            source="cron.month"
            choices={monthChoices}
            label={translate("app.schedule.form.fields.month")}
            helperText={translate("app.schedule.form.fields.monthHelper")}
            hidden={["minute", "hour", "week", "day", "month"].includes(every)}
            defaultValue={month}
            sx={{ width: "100%" }}
            limitTags={4}
            onChange={(e) => setMonth(e)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4} sx={{ maxWidth: "100% !important" }}>
          <StyledAutocompleteInput
            multiple
            source="cron.dayOfMonth"
            choices={dayOfMonthChoices}
            label={translate("app.schedule.form.fields.dayOfMonth")}
            helperText={translate("app.schedule.form.fields.dayOfMonthHelper")}
            hidden={["minute", "hour", "week", "day"].includes(every)}
            defaultValue={dayOfMonth}
            sx={{ width: "100%" }}
            limitTags={8}
            onChange={(e) => setDayOfMonth(e)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={4} sx={{ maxWidth: "100% !important" }}>
          <StyledAutocompleteInput
            multiple
            source="cron.dayOfWeek"
            choices={dayOfWeekChoices}
            label={translate("app.schedule.form.fields.dayOfWeek")}
            helperText={translate("app.schedule.form.fields.dayOfWeekHelper")}
            hidden={["minute", "hour", "day"].includes(every)}
            defaultValue={dayOfWeek}
            sx={{ width: "100%" }}
            limitTags={4}
            onChange={(e) => setDayOfWeek(e)}
          />
        </Grid>
      </Grid>

      <StyledAutocompleteInput
        source="timeZone"
        label={translate("app.schedule.form.fields.timeZone")}
        validate={[required()]}
        choices={timezonesChoices}
        defaultValue={timeZone}
        sx={{ marginTop: 0, minWidth: 210 }}
        onChange={(e) => setTimeZone(e)}
      />

      <TimeInput
        source="cron.time"
        label={translate("app.schedule.form.fields.time")}
        validate={[required()]}
        defaultValue={time}
        sx={{ marginTop: 0 }}
        onChange={(e) => setTimeFromInput(e)}
      />

      <TextInput
        source="recurring.cron"
        label="Cron"
        defaultValue={cron}
        style={{ display: "none" }}
      />

      <Box>
        <Typography variant="subtitle1">
          {translate("app.schedule.form.fields.upcomingExecutions")}
        </Typography>
        {cronIterates.map((date) => (
          <Typography key={date} variant="body2">
            {moment(date).fromNow()}
            {" - "}
            {moment(date).format("YYYY-MM-DD HH:mm:ss")}
          </Typography>
        ))}
      </Box>
    </>
  );
};

const TimePlannerInput = () => {
  const type = useWatch<{ type: ETaskType }>({ name: "type" });

  if (type === ETaskType.OneTime) {
    return <OneTimePlanner />;
  }

  if (type === ETaskType.Recurring) {
    return <RecurringPlanner />;
  }

  return <></>;
};

export default TimePlannerInput;
