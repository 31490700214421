import { sanitizeListRestProps, useAuthenticated, useTranslate } from "ra-core";
import {
  CreateButton,
  Filter,
  FilterProps,
  FunctionField,
  ListToolbarProps,
  TextField,
  TopToolbar,
} from "react-admin";
import cronParser from "cron-parser";
import moment from "moment";
import { ReactElement } from "react";
import {
  listTopFilter,
  StyledDatagrid,
  StyledList,
  StyledSelect,
} from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import CustomPagination from "../../../components/common/CustomPagination";

const SchedulePagination = (): ReactElement => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const ScheduleListFilter = (props: Partial<FilterProps>): ReactElement => (
  <Filter {...props}>
    <StyledSelect
      alwaysOn
      source="active"
      className="list-select-input"
      label="app.auth.active"
      emptyValue="all"
      emptyText={"app.helpdesk.all"}
      choices={[
        { id: "active", name: "app.helpdesk.active" },
        { id: "inactive", name: "app.helpdesk.inactive" },
      ]}
      variant="outlined"
    />
  </Filter>
);

const ScheduleListTopToolbar = (props: ListToolbarProps): ReactElement => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const ScheduleList = (): ReactElement => {
  useAuthenticated();
  const translate = useTranslate();

  return (
    <StyledList
      className="styled-list"
      pagination={<SchedulePagination />}
      filters={<ScheduleListFilter />}
      actions={<ScheduleListTopToolbar />}
      empty={false}
      queryOptions={{ meta: { active: "all" } }}
    >
      <SkeletonLoaderList>
        <StyledDatagrid
          bulkActionButtons={false}
          rowClick="show"
          empty={<span>{translate("app.schedule.form.emptyTasks")}</span>}
        >
          <TextField
            source="name"
            label={translate("app.schedule.list.name")}
          />
          <FunctionField
            source="type"
            label={translate("app.schedule.list.type")}
            render={(record: any) => (
              <span>{translate(`app.schedule.taskTypes.${record.type}`)}</span>
            )}
          />
          <FunctionField
            source="handler"
            label={translate("app.schedule.list.handler")}
            render={(record: any) => (
              <span>
                {translate(`app.schedule.handlers.${record.handler}`)}
              </span>
            )}
          />
          <FunctionField
            label={translate("app.schedule.list.upcoming_run")}
            render={(record: any) => {
              const type = record.type;

              if (type === "one-time" && record?.oneTime?.date) {
                return (
                  <span>
                    {moment(record.oneTime.date).isBefore(moment())
                      ? "-"
                      : moment(record.oneTime.date).fromNow()}
                  </span>
                );
              }

              if (type === "recurring" && record?.recurring?.cron) {
                const cron = record.recurring.cron;
                const timeZone = record.timeZone;
                const nextDate = cronParser
                  .parseExpression(cron, { tz: timeZone })
                  .next()
                  .toDate();

                return <span>{moment(nextDate).fromNow()}</span>;
              }

              return <span>{translate("app.schedule.list.unknown")}</span>;
            }}
          />
          <FunctionField
            label="Status"
            render={(record: any) => (
              <span>{translate(`app.helpdesk.status.${record.active}`)}</span>
            )}
          />
        </StyledDatagrid>
      </SkeletonLoaderList>
    </StyledList>
  );
};

export default ScheduleList;
