const appPolishMessages = {
  "resources.maps/buildings.fields.properties.name": "Nazwa",
  "resources.maps/map-levels.fields.properties.name": "Nazwa",
  "resources.maps/map-levels.fields.properties.short_name": "Nazwa skrócona",
  "resources.marketing/landing-page-media-item.name": "Element multimedialny",
  app: {
    auth: {
      parent: "Użytkownicy",
      users: "Użytkownicy",
      groups: "Grupy",
      name: "Nazwa",
      path: "Ścieżka",
      code: "Kod",
      access_account_limit: "Limit kont HID",
      remove_marketplace: "Usuń marketplace",
      choose_marketplace: "Wybierz marketplace",
      seller_profile: "Profil sprzedawcy",
      add_marketplace: "Dodaj marketplace",
      image: "Zdjęcie",
      empty: "Brak",
      consent_templates: "Zgody",
      consents: "Wyrażone zgody",
      onboarding_pages: "Strony wprowadzające",
      order: "Kolejność",
      field_is_required: "Pole jest wymagane",
      title: "Tytuł",
      content: "Kontent",
      required: "Wymagany",
      optIn: "OptIn",
      active: "Aktywny",
      hid_accounts_limit: "Limit kont HID",
      email: "Email",
      surname: "Nazwisko",
      first_name: "Imię",
      phoneNumber: "Numer",
      removed: "Usunięto",
      email_verified: "Email zweryfikowany",
      has_hid_account: "Konto HID",
      remove_hid_account: "Usuń konto HID",
      summary: "Podsumowanie",
      roles: "Role",
      role: "Rola",
      add_role: "Dodaj role",
      access_cards: "Karty dostępowe",
      card_number: "Numer karty",
      device_description: "Opis urządzenia",
      description: "Opis",
      created_at: "Data utworzenia",
      updated_at: "Data aktualizacji",
      remove: "Usuń",
      activate: "Aktywuj",
      deactivate: "Deaktywuj",
      activate_deactivate: "Aktywuj/Deaktywuj",
      password_reset: "Reset hasła",
      change_group: "Zmień Grupę",
      add_hid_account: "Dodaj konto HID",
      remove_role: "Usuń rolę",
      counterId: "ID parkingu grupy",
      security_role: "Ochrona",
      supplier_role: "Dostawca",
      value: "Wartość",
      login: {
        confirm_changes: "Potwierdź zmiany",
        reset_password: "Zresetuj swoje hasło",
        title: "Zaloguj się do swojego konta",
        code: "Kod",
        password: "Hasło",
        forgot_password: "Zapomniałem/am hasła",
      },
      next: "Dalej",
      back: "Wstecz",
      delete_confirm: "Czy na pewno chcesz usunąć użytkownika %{name}",
      confirmation: "Potwierdzenie",
      search: "Szukaj",
      organization: "Organizacja",
      choose_organization: "Wybierz organizację",
    },
    mobile: {
      mobile_modules: "Moduły mobilne",
      mobile_parent: "Mobile",
      advertisingText: "Tekst reklamowy",
      add_level: "Dodaj poziom",
      add_path: "Dodaj ścieżkę",
      module: "Moduł",
      add_page: "Dodaj stronę",
      mobile_module_tags: "Tagi",
      createdAt: "Data utworzenia",
      updatedAt: "Data aktualizacji",
      name: "Nazwa",
      type: "Typ",
      order: "Kolejność",
      isActive: "Aktywny",
      onlyForLoggedIn: "Tylko dla zalogowanych",
      calendar: "Kalendarz",
      manual: "Katalog",
      accesses: "Dostępy",
      role: "Rola",
      group: "Grupa",
      url: "URL",
      landing_page: "Landing page",
      faq: "FAQ",
      short_description: "Krótki opis",
      duration: "Czas trwania",
      station: "Stacja",
      default: "Domyślny w aplikacji mobilnej",
      description: "Opis",
      web_frame: "Strona w ramce",
      module_updated_success: "Moduł został zaktualizowany",
      module_general: "Informacja ogólna",
      pages: "Strony",
      edit: "Edytuj",
      module_was_created: "Moduł został stworzony",
      active: "Aktywny",
      icon: "Ikona",
      WEB_FRAME: "Strona w ramce",
      inactive: "Nieaktywny",
      archived: "Zarchiwizowany",
      position: "Pozycja",
    },
    parking: {
      parking: "Parking",
      model: "Model",
      color: "Kolor",
      brand: "Marka",
      vehicles: "Pojazdy",
      plate_number: "Numer rejestracyjny",
      extend_time: "Przedłuż czas",
      extend: "Przedłuż",
      extend_success: "Sukces",
      extend_time_for: "Przedłuż czas dla",
      in_minutes: "W minutach",
      registered_vehicles: "Zarejestrowane pojazdy",
      from_date: "Utworzono od",
      transactions: "Transakcje",
      transaction: "Transakcja",
      issuer: "Emitent",
      user_first_name: "Imię użytkownika",
      user_last_name: "Nazwisko użytkownika",
      user_email: "Email użytkownika",
      other: "Inne",
      vehicles_not_found: "Nie znaleziono pojazdów użytkowników",
      requested_by_customer: "Na żądanie klienta",
      duplicate: "Duplikat",
      reason: "Powód",
      description_of_reason: "Opis powodu",
      failure_reason: "Powód niepowodzenia",
      to_amount: "Kwota do",
      from_amount: "Kwota od",
      create: "Stwórz",
      refund_creating: "Tworzenie zwrotu",
      refunds: "Zwroty",
      transaction_statuses: {
        processing: "Przetwarzanie",
        failed: "Nieudana",
        succeeded: "Sukces",
        canceled: "Anulowana",
      },
      to_date: "Utworzono do",
      currency: "Waluta",
      created_at: "Utworzono",
      user: "Użytkownik",
      user_phone_number: "Numer telefonu",
      amount: "Kwota",
      refund_was_created: "Zwrot został stworzony",
      refund: "Zwrot",
      lastTransactionAt: "Data ostatniej transakcji",
      refund_status: "Status zwrotu",
      payment: {
        errors: {
          invalidAmount: "Nieprawidłowa kwota do zwrotu",
        },
      },
      transactionsCount: "Liczba transakcji",
      vehicles_amount: "Licza zarejestrowanych pojazdów",
      ticket: "Bilet",
      refunded_amount: "Kwota zwrócona",
      transaction_type: "Typ transakcji",
      transaction_status: "Status transakcji",
      prepaid: {
        welcome_message: "Witaj na parkingu %{name}",
        promo_message:
          "Zarejestruj swój samochód i odbierz %{hours} darmowe godziny parkowania",
        page_error_message: "Strona nie istnieje",
        page_error_link: "Strona płatności za parking",
        organization_error_message:
          "Nie udało się załadować strony, sprawdź swoje połączenie z internetem i spróbuj ponownie klikając w poniższy link.",
        organization_error_link: "Spróbuj ponownie",
        register: "Zarejestruj",
        second_step_title:
          "Dziękujemy za rejestrację. Teraz możesz skorzystać z darmowych %{hours} godzin parkowania",
        second_step_question: "Jak długo chcesz parkować?",
        second_step_prolongate_message:
          "Po upływie darmowych %{hours} godzin będziesz mógł przedłużyć postój na parkingu dokonując płatności.",
        your_stopover_time: "Czas Twojego postoju to",
        your_plate_number: "Twój numer rejestracyjny",
        standard_time_title: "%{hours} godziny za darmo",
        more_time_title: "Dłużej (opłać bilet)",
        third_step_title: "Określ czas parkowania używając poniższych pól",
        minutes: "Minuty",
        hours: "Godziny",
        pay_parking_until: "Opłacisz parkowanie do",
        confirm: "Zatwierdź",
        order_and_pay: "Zamawiam i płacę",
        form_title: "Wypełnij formularz, a następnie dokonaj płatności",
        first_name: "Imię",
        last_name: "Nazwisko",
        email: "Adres e-mail",
        phone_number: "Numer telefonu",
        sms_step_title:
          "Dziękujemy za zakup. Na podany numer telefonu otrzymasz potwierdzenie zakupu biletu",
        sms_step_subtitle:
          "Przypomnimy Ci również, że bilet wygasa i trzeba go przedłużyć",
        finish: "Zakończ",
        no_notifications: "Nie chcę otrzymywać powiadomień",
        make_a_payment: "Dokonaj płatności",
        pay: "Zapłać",
        go_to_payment: "Przejdź do płatności",
        payment_redirect_message:
          "Za chwilę zostaniesz przekierowany do ekranu płatności. Jeżeli przekierowanie nie następuje, kliknij w poniższy link",
        price: "Opłata",
        accept_text:
          "Rejestrując się na parkingu potwierdzam, że zapoznałem się i akceptuję",
        accept_text_and: "oraz",
        accept_text_privacy_policy: "politykę prywatności",
        accept_text_parking_regulations: "regulamin",
        accept_text_privacy_policy_capitalize: "Politykę prywatności",
        accept_text_parking_regulations_capitalize: "Regulamin",
        accept_text_parking_price_capitalize: "Cennik",
        accept: "Akceptuję",
      },
      menu: {
        parking_price: "Cennik parkingu",
        parking_regulations: "Regulamin parkingu",
        parking_privacy_policy: "Polityka prywatności",
        all_parking: "Wszystkie parkingi",
      },
      errors: {
        providerNotConfigured: "Nie ma skonfigurowanego parkingu",
        time_cannot_be_zero: "Czas nie może być równy 0",
      },
      error: {
        ticket: {
          activeAlreadyExists: "Aktywny bilet już istnieje",
        },
      },
      reservations: "Rezerwacje",
      reservationFrom: "Rezerwacja od",
      reservation_was_canceled: "Rezerwacja została anulowana",
      reservation_was_created: "Rezerwacja została utworzona",
      reservation_was_updated: "Rezerwacja została zaktualizowana",
      reservationTo: "Rezerwacja do",
      choose_user_warning_message: "Wpisz co najmniej 3 znaki",
      cancel: "Anuluj",
      edit: "Zaktualizuj",
      validation: {
        creating_form: "Musisz podać użytkownika i numer rejestracyjny",
      },
      schedule: {
        handlers: {
          create_report: {
            labels: {
              period: "Okres czasu",
              from: "Od",
              to: "Do",
              sheets: "Arkusze",
              format: "Format",
            },
            form: {
              sheets: {
                summary: "Podsumowanie",
                customers: "Klienci",
                tickets: "Biety",
              },
              periods: {
                today: "Dzisiaj",
                yesterday: "Wczoraj",
                last7days: "Ostatnie 7 dni",
                last30days: "Ostatnie 30 dni",
                lastWeek: "Ostatni tydzień",
                lastMonth: "Ostatni miesiąc",
                lastYear: "Ostatni rok",
                thisWeek: "Ten tydzień",
                thisMonth: "Ten miesiąc",
                thisYear: "Ten rok",
                custom: "Niestandardowy",
              },
              email_info:
                "Raport zostanie wysłany na twój adres email: %{email}",
            },
          },
        },
      },
    },
    attributes: {
      color: "Kolor",
      active: "Aktywny",
      archived: "Zarchiwizowany",
      inactive: "Nieaktywny",
      all: "Wszystkie",
      statuses: "Statusy",
      name: "Nazwa",
      roles: "Role",
      type: "Typ",
      isOptional: "Czy opcjonalny",
      isRemovable: "Czy usuwalny",
      isSavingNewValues: "Czy zapisywane nowe wartości",
      singleLineText: "Tekst jednowierszowy",
      multiLineText: "Tekst wieloliniowy",
      integer: "Liczba całkowita",
      decimal: "Liczba zmiennoprzecinkowa",
      dateTime: "Data",
      singleSelect: "Pojedynczy wybór",
      position: "Pozycja",
      attribute_name_en: "Wprowadź nazwę opcji w języku angielskim",
      attribute_name_pl: "Wpisz nazwę opcji w języku polskim",
      options: "Opcje",
      admin: "Admin",
      customer: "Klient",
      updated: "Zaktualizowano",
      added: "Dodano",
      tickets_attachment_drop_file: "Upuść plik tutaj, maksymalny rozmiar: ",
      unauthenticated: "Nieuwierzytelniony",
      attribute: {
        active: "Aktywny",
        inactive: "Nieaktywny",
        single_line_text: "Tekst jednowierszowy",
        multi_line_text: "Tekst wielowierszowy",
        status: "Status",
        integer: "Liczba całkowita",
        decimal: "Liczba zmiennoprzecinkowa",
        dateTime: "Data i godzina",
        single_select: "Pojedynczy wybór",
        length: "Długość",
        mass: "Masa",
        plate_number: "Numer rejestracyjny",
        phone: "Numer telefonu",
        email: "Email",
        duration: "Czas trwania",
        boolean: "Checkbox",
      },
    },
    delivery_zone: {
      section_name: "Strefa dostaw",
      ramp_attributes: "Atrybuty rampy",
      reservation_attributes: "Atrybuty rezerwacji",
    },
    helpdesk: {
      color: "Kolor",
      helpdesk: "Helpdesk",
      tickets: "Zgłoszenia",
      active: "Aktywny",
      archived: "Zarchiwizowany",
      inactive: "Nieaktywny",
      all: "Wszystkie",
      statuses: "Statusy",
      ticket_attributes: "Atrybuty zgłoszenia",
      name: "Nazwa",
      roles: "Role",
      type: "Typ",
      isOptional: "Czy opcjonalny",
      isRemovable: "Czy usuwalny",
      isSavingNewValues: "Czy zapisywane nowe wartości",
      singleLineText: "Tekst jednowierszowy",
      multiLineText: "Tekst wieloliniowy",
      integer: "Liczba całkowita",
      decimal: "Liczba zmiennoprzecinkowa",
      dateTime: "Data",
      singleSelect: "Pojedynczy wybór",
      position: "Pozycja",
      attribute_name_en: "Wprowadź nazwę opcji w języku angielskim",
      attribute_name_pl: "Wpisz nazwę opcji w języku polskim",
      options: "Opcje",
      admin: "Administrator",
      customer: "Klient",
      unauthenticated: "Nieuwierzytelniony",
      updated: "Zaktualizowano",
      added: "Dodano",
      tickets_attachment_drop_file: "Upuść plik tutaj, maksymalny rozmiar: ",
      status: {
        active: "Aktywny",
        inactive: "Nieaktywny",
      },
      attribute: {
        active: "Aktywny",
        inactive: "Nieaktywny",
        single_line_text: "Tekst jednowierszowy",
        multi_line_text: "Tekst wielowierszowy",
        status: "Status",
        integer: "Liczba całkowita",
        decimal: "Liczba zmiennoprzecinkowa",
        dateTime: "Data i godzina",
        single_select: "Pojedynczy wybór",
      },
      workflow: {
        active: "Aktywne",
        inactive: "Nieaktywne",
        notConnectedRequiredNodes:
          "Musisz mieć co najmniej 1 połączenie do połączenia początkowego i co najmniej 1 do końcowego bloku",
        activeMessage:
          "Po kliknięciu Zapisz wszystkie inne przepływy pracy z taką samą rolą staną się nieaktywne",
      },
      ticketsPage: {
        createTicketTitle: "Dodaj zgłoszenie",
      },
      add_comment: "Dodaj komentarz",
      comment: "Komentarz",
      comment_added: "Dodano komentarz",
      user: "Użytkownik",
      createdAt: "Stworzono",
      content: "Kontent",
      field: "Pole",
      action: "Akcja",
      before: "Przed",
      after: "Po",
      comments_list: "Lista komentarzy",
      ticket_details: "Dane zgłoszenia",
      ticket_history: "Historia zgłoszenia",
      empty_ticket_comments_list: "Lista jest pusta",
      empty_ticket_history: "Historia jest pusta",
      attachment: "Załącznik",
      add_attachment: "Dodaj załącznik",
      change_status: "Zmień status",
      add_new_option: "Dodaj nową opcję",
      reporter: "Zgłaszający",
      attribute_value: "Wartość",
      attribute_name: "Nazwa",
      title: "Tytuł",
      description: "Opis",
      priority: "Priorytet",
      assignee_to_user: "Przypisz",
      choose_user: "Wybierz użytkownika",
      choose_vehicle: "Wybierz pojazd",
      date_of_change: "Data zmiany",
      responsible_user: "Odpowiedzialny",
      created_by: "Stworzony przez",
      assign_workflow: "Przypisz workflow",
      assign_user: "Przypisz użytkownika",
      assigned_user: "Przypisany użytkownik",
    },
    workflow: {
      admin: "Administrator",
      customer: "Klient",
      unauthenticated: "Nieuwierzytelniony",
    },
    system: {
      system_admin: "Administracja",
      system_language: "Język",
      system_profile: "Profil",
      system_notifications: "Powiadomienia",
      system_lang_english: "Angielski",
      system_lang_polish: "Polski",
      system_logout: "Wyloguj",
      system_application: "Aplikacja",
      system_building_management: "Zarządzanie budynkiem",
      system_facilities: "Udogodnienia",
      system_parkings: "Parkingi",
      system_entertainments: "Rozrywka",
      system_marketplace: "Zakupy",
      system_summary: "Podsumowamie",
      system_cancel: "Anuluj",
    },
    notifications: {
      success: {
        status_changed_correctly: "Status został zmieniony poprawnie",
        operation_finished_successfully: "Operacja zakończona poprawnie",
        hid_account_created: "Konto HID został utworzone poprawnie",
        hid_account_deleted: "Konto HID zostało usunięte poprawnie",
        card_hid_was_deleted: "Karta HID została usunięta poprawnie",
        sync_successfully_finished: "Synchronizacja zakończona poprawnie",
        request_was_sent_to_parking_system:
          "Żądanie zostało wysłane do systemu parkingowego",
        password_changed: "Hasło zostało zmienione",
      },
      error: {
        request_failed_with_status_code_500: "Żądanie nie powiodło się",
        error_occured: "Wystąpił błąd",
        unauthorized: "Sesja wygasła",
        error: "Błąd",
        undefined: "Nieokreślony błąd",
        status_changed_error: "Wystąpił błąd przy zmianie statusu",
        hid_account_creation_error: "Wystąpił błąd przy tworzenia konta HID",
        hid_account_deletion_error: "Wystąpił błąd przy usuwaniu konta HID",
        card_hid_deletion_error: "Wystąpił błąd przy usuwaniu karty HID",
        access_denied: "Brak dostępu",
        unexpected_error: "Wystąpił błąd. Spróbuj ponownie.",
      },
    },
    attachment: "Załącznik",
    validation: {
      required_field: "Pole wymagane",
      minimum_characters: "Minimalna długość %{number} znaków",
      maximum_characters: "Maksymalna długość %{number} znaków",
      only_numbers_allowed: "Dozwolone tylko liczby",
      email: "Nieprawidłowy mail",
      password_requirements:
        "Hasło musi zawierać min 8 znaków, znak specjalny, cyfrę i wielką literę",
      only_numbers_and_uppercase_letters_are_allowed:
        "Dozwolone są tylko cyfry i wielkie litery",
      only_letters_and_numbers: "Tylko litery i cyfry",
      incorrect_format: "Nieprawidłowy format",
    },
    form: {
      password: "Hasło",
      email: "Email",
    },
    schedule: {
      schedule_parent: "Harmonogram",
      tasks: "Zadania",
      label: "Harmonogram",
      organization: {
        system: "System",
      },
      form: {
        sections: {
          general: "Ogólne",
          time: "Czas",
          action: "Akcja",
        },
        fields: {
          id: "ID",
          createdAt: "Utworzono",
          updatedAt: "Ostatnia aktualizacja",
          createdBy: "Utworzone przez",
          startedAt: "Rozpoczęto o",
          finishedAt: "Zakończono o",
          timestamp: "Czas",
          level: "Poziom",
          message: "Wiadomość",
          requestId: "ID żądania",
          hostname: "Nazwa hosta",
          finishStatus: "Status zakończenia",
          name: "Nazwa",
          description: "Opis",
          type: "Typ",
          time: "Czas",
          timeZone: "Strefa czasowa",
          cron: "Cron",
          taskType: "Typ zadania",
          actionType: "Typ akcji",
          action: "Akcja",
          recurring: "Powtarzaj",
          oneTime: "Wykonaj raz",
          upcomingExecutions: "Nadchodzące wykonania",
          date: "Data",
          every: "Wykonaj co",
          year: "Rok",
          month: "Miesiąc",
          week: "Tydzień",
          day: "Dzień",
          hour: "Godzina",
          minute: "Minuta",
          dayOfWeek: "Dzień tygodnia",
          dayOfMonth: "Dzień miesiąca",
          monthHelper:
            "Wybierz miesiące, w których zadanie będzie wykonywane, lub pozostaw puste dla każdego miesiąca.",
          dayOfMonthHelper:
            "Wybierz dni miesiąca, w których zadanie będzie wykonywane, lub pozostaw puste dla każdego dnia.",
          dayOfWeekHelper:
            "Wybierz dni tygodnia, w których zadanie będzie wykonywane, lub pozostaw puste dla każdego dnia.",
          months: {
            january: "Styczeń",
            february: "Luty",
            march: "Marzec",
            april: "Kwiecień",
            may: "Maj",
            june: "Czerwiec",
            july: "Lipiec",
            august: "Sierpień",
            september: "Wrzesień",
            october: "Październik",
            november: "Listopad",
            december: "Grudzień",
          },
          daysOfWeek: {
            monday: "Poniedziałek",
            tuesday: "Wtorek",
            wednesday: "Środa",
            thursday: "Czwartek",
            friday: "Piątek",
            saturday: "Sobota",
            sunday: "Niedziela",
          },
        },
        emptyLogs: "Brak logów",
        emptyTasks: "Brak zadań",
        emptyRuns: "Brak wykonania",
      },
      list: {
        unknown: "Nieznany",
        name: "Nazwa",
        type: "Typ",
        module: "Moduł",
        handler: "Aktywność",
        upcoming_run: "Nadchodzące wykonanie",
        status: "Status",
        organization: "Organizacja",
        searchPlaceholder: "Szukaj",
        search: "Szukaj",
        statuses: {
          active: "Aktywny",
          inactive: "Nieaktywny",
          all: "Wszystkie",
        },
      },
      show: {
        tabs: {
          general: "Ogólne",
          payload: "Dane wejściowe",
          runs: "Wykonania",
        },
      },
      runStatuses: {
        completed: "Zakończone",
        failed: "Nieudane",
        canceled: "Anulowane",
        running: "Wykonywane",
        pending: "Oczekuje",
      },
      handlers: {
        CreateTicket: "Utwórz zgłoszenie",
        CreateReport: "Utwórz raport",
      },
      taskTypes: {
        "one-time": "Wykonaj raz",
        recurring: "Powtarzaj",
      },
    },
  },
};

export default appPolishMessages;
