import {
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  ReferenceManyField,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useResourceDefinition,
  useShowContext,
  useTranslate,
} from "react-admin";
import { useParams } from "react-router";
import moment from "moment";
import cronParser from "cron-parser";
import { Chip } from "@mui/material";
import { ReactElement } from "react";
import {
  listTopFilter,
  StyledDatagrid,
  StyledShow,
} from "../../../themes/styles";
import { ConfigManager } from "../../../constants/ConfigManager";
import CustomPagination from "../../../components/common/CustomPagination";
import { ETaskRunStatus } from "../../../types/enums/schedule.enums";
import taskRunColors from "../../schedule/task-runs/statusColors";
import { IScheduleResourceOptions } from "../../../types/interfaces/schedule.interfaces";

const SchedulePagination = (): ReactElement => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const ScheduleShowTopToolbar = (): ReactElement => {
  const { record } = useShowContext();

  return (
    <TopToolbar>
      <EditButton className="edit-button" />
      <DeleteWithConfirmButton
        className="delete-button"
        translateOptions={{ id: "", name: record?.name || "Loading" }}
      />
    </TopToolbar>
  );
};

const ScheduleShow = (): ReactElement => {
  const translate = useTranslate();
  const { id } = useParams();
  const orgId = ConfigManager.getInstance().getOrgId();
  const { options } = useResourceDefinition<IScheduleResourceOptions>();

  return (
    <StyledShow actions={<ScheduleShowTopToolbar />}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="app.schedule.show.tabs.general">
          <TextField source="id" label="app.schedule.form.fields.id" />
          <DateField
            source="createdAt"
            showTime={true}
            label="app.schedule.form.fields.createdAt"
          />
          <DateField
            source="updatedAt"
            showTime={true}
            label="app.schedule.form.fields.updatedAt"
          />
          <FunctionField
            label="app.schedule.form.fields.createdBy"
            render={(record: any) => {
              if (!record.createdBy) {
                return "-";
              }

              return (
                <span>{`${record.createdBy.firstName} ${record.createdBy.lastName}`}</span>
              );
            }}
          />
          <TextField source="name" label="app.schedule.form.fields.name" />
          <FunctionField
            source="description"
            label="app.schedule.form.fields.description"
            render={(record: any) => {
              if (!record.description) {
                return "-";
              }

              return <span>{record.description}</span>;
            }}
          />
          <FunctionField
            source="active"
            label="app.schedule.list.status"
            render={(record: any) => {
              return (
                <span>
                  {translate(`app.schedule.list.statuses.${record.active}`)}
                </span>
              );
            }}
          />
          <FunctionField
            source="type"
            label="app.schedule.form.fields.type"
            render={(record: any) => {
              return (
                <span>
                  {translate(`app.schedule.taskTypes.${record.type}`)}
                </span>
              );
            }}
          />
          <FunctionField
            label={translate("app.schedule.list.upcoming_run")}
            render={(record: any) => {
              const type = record.type;
              const timeZone = record.timeZone;

              if (type === "one-time" && record?.oneTime?.date) {
                return (
                  <span>
                    {moment(record.oneTime.date).tz(timeZone).fromNow()}
                  </span>
                );
              }

              if (type === "recurring" && record?.recurring?.cron) {
                const cron = record.recurring.cron;
                const nextDate = cronParser
                  .parseExpression(cron, { tz: timeZone })
                  .next()
                  .toDate();

                return <span>{moment(nextDate).fromNow()}</span>;
              }

              return <span>{translate("app.schedule.list.unknown")}</span>;
            }}
          />
          <FunctionField
            source="handler"
            label={"app.schedule.list.handler"}
            render={(record: any) => (
              <span>
                {translate(`app.schedule.handlers.${record.handler}`)}
              </span>
            )}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="app.schedule.show.tabs.runs"
          disabled={!options?.module}
        >
          <ReferenceManyField
            reference={`${options?.module}/admin/${orgId}/schedule/tasks/${id}/runs`}
            target="id"
            filter={{}}
            sortable={false}
            pagination={<SchedulePagination />}
            emptyText={translate("app.schedule.form.emptyRuns")}
          >
            <StyledDatagrid
              bulkActionButtons={false}
              sort={{ field: "startedAt", order: "DESC" }}
              empty={<span>{translate("app.schedule.form.emptyRuns")}</span>}
            >
              <DateField
                source="startedAt"
                label="app.schedule.form.fields.startedAt"
                showTime={true}
              />
              <FunctionField
                source="finishedAt"
                label="app.schedule.form.fields.finishedAt"
                render={(record: any) => {
                  if (record.finishedAt) {
                    return <DateField source="finishedAt" showTime={true} />;
                  }

                  return <span>Not yet finished</span>;
                }}
              />
              <FunctionField
                source="status"
                label="app.schedule.form.fields.finishStatus"
                render={(record: { status: ETaskRunStatus }) => {
                  return (
                    <Chip
                      sx={{
                        ...taskRunColors[record.status],
                      }}
                      label={translate(
                        `app.schedule.runStatuses.${record.status}`,
                      )}
                    />
                  );
                }}
              />
            </StyledDatagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </StyledShow>
  );
};

export default ScheduleShow;
