export const attributeTypes = [
  { id: "single-line-text", name: "app.attributes.attribute.single_line_text" },
  {
    id: "multi-line-text",
    name: "app.attributes.attribute.multi_line_text",
  },
  { id: "email", name: "app.attributes.attribute.email" },
  { id: "phone", name: "app.attributes.attribute.phone" },
  { id: "plate-number", name: "app.attributes.attribute.plate_number" },
  { id: "length", name: "app.attributes.attribute.length" },
  { id: "mass", name: "app.attributes.attribute.mass" },
  { id: "integer", name: "app.attributes.attribute.integer" },
  { id: "decimal", name: "app.attributes.attribute.decimal" },
  { id: "date-time", name: "app.attributes.attribute.dateTime" },
  { id: "duration", name: "app.attributes.attribute.duration" },
  { id: "boolean", name: "app.attributes.attribute.boolean" },
  { id: "single-select", name: "app.attributes.attribute.single_select" },
];
