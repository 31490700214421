const logLevelColors: Record<string, string> = {
  debug: "#2196F3",
  log: "#2196F3",
  info: "#4CAF50",
  warning: "#FFA500",
  error: "#F44336",
  critical: "#F44336",
};

export default logLevelColors;
