import { Resource } from "ra-core";
import { Route } from "react-router";
import { ReactElement } from "react";
import { Roles } from "../../types/enums/roles";
import ScheduleAppIcon from "../../components/icons/ScheduleAppIcon";
import tasksResources from "./tasks";
import TaskRunList from "./task-runs/TaskRunList";

const schedule = (permissions: string[] | undefined): ReactElement[] => {
  const tasksUrl = "schedule/tasks";

  if (!permissions || !permissions.includes(Roles.ROLE_APP_ADMIN)) {
    return [];
  }

  return [
    <Resource
      key="schedule-parent"
      name="schedule-parent"
      icon={ScheduleAppIcon}
      options={{
        label: "app.schedule.schedule_parent",
        isMenuParent: true,
        sectionName: "app.system.system_admin",
      }}
    />,
    <Resource
      key={tasksUrl}
      name={tasksUrl}
      {...tasksResources}
      options={{
        label: "app.schedule.tasks",
        menuParent: "schedule-parent",
      }}
    >
      <Route path=":id/runs" element={<TaskRunList />} />
    </Resource>,
  ];
};

export default schedule;
