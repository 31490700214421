const ScheduleAppIcon = ({ size = 18, color = "#606060", style = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="#e8eaed"
      style={style}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm.5-13H11v6l5.25 3.15.75-1.23-4.5-2.67z"
        fill={color}
      />
    </svg>
  );
};

export default ScheduleAppIcon;
