import {
  ArrayField,
  ChipField,
  DateField,
  FunctionField,
  ReferenceManyField,
  TabbedShowLayout,
  TextField,
  useTranslate,
} from "react-admin";
import { useParams } from "react-router";
import { Chip } from "@mui/material";
import { StyledDatagrid, StyledShow } from "../../../themes/styles";
import { ETaskRunStatus, ETaskType } from "../../../types/enums/schedule.enums";
import taskRunColors from "../task-runs/statusColors";
import logLevelColors from "../task-runs/logLevelColors";

const TaskRunPanel = () => {
  const translate = useTranslate();
  return (
    <ArrayField
      source="logs"
      emptyText={translate("app.schedule.form.emptyLogs")}
    >
      <StyledDatagrid
        bulkActionButtons={false}
        empty={<span>{translate("app.schedule.form.emptyLogs")}</span>}
        sx={{
          "& .column-message": { width: "100%" },
        }}
      >
        <DateField
          source="timestamp"
          label="app.schedule.form.fields.timestamp"
          showTime={true}
          sx={{ whiteSpace: "nowrap", fontWeight: "500 !important" }}
        />
        <FunctionField
          source="level"
          label="app.schedule.form.fields.level"
          render={(record: { level: string }) => (
            <span
              style={{
                color: logLevelColors[record.level],
                textTransform: "uppercase",
                fontWeight: "500 !important",
              }}
            >
              {record.level}
            </span>
          )}
        />
        <TextField source="message" label="app.schedule.form.fields.message" />
      </StyledDatagrid>
    </ArrayField>
  );
};

const TaskShow = () => {
  const translate = useTranslate();
  const { id } = useParams();

  return (
    <StyledShow>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="app.schedule.show.tabs.general">
          <TextField source="id" label="app.schedule.form.fields.id" />
          <DateField
            source="createdAt"
            showTime={true}
            label="app.schedule.form.fields.createdAt"
          />
          <DateField
            source="updatedAt"
            showTime={true}
            label="app.schedule.form.fields.updatedAt"
          />
          <FunctionField
            label="app.schedule.form.fields.createdBy"
            render={(record: any) => {
              if (!record.createdBy) {
                return "-";
              }

              return (
                <span>{`${record.createdBy.firstName} ${record.createdBy.lastName} (${record.createdBy.email})`}</span>
              );
            }}
          />
          <TextField source="name" label="app.schedule.form.fields.name" />
          <FunctionField
            source="description"
            label="app.schedule.form.fields.description"
            render={(record: any) => {
              if (!record.description) {
                return "-";
              }

              return <span>{record.description}</span>;
            }}
          />
          <FunctionField
            source="active"
            label="app.schedule.list.status"
            render={(record: any) => {
              return (
                <span>
                  {translate(`app.schedule.list.statuses.${record.active}`)}
                </span>
              );
            }}
          />
          <TextField source="groupId" label="app.schedule.list.organization" />
          <FunctionField
            source="type"
            label="app.schedule.form.fields.type"
            render={(record: any) => {
              return (
                <span>
                  {translate(`app.schedule.taskTypes.${record.type}`)}
                </span>
              );
            }}
          />
          <FunctionField
            label="app.schedule.form.fields.time"
            render={(record: any) => {
              if (record.type === ETaskType.Recurring) {
                return (
                  <TextField
                    source="recurring.cron"
                    label="app.schedule.form.fields.cron"
                  />
                );
              }

              if (record.type === ETaskType.OneTime) {
                return (
                  <DateField
                    source="oneTime.date"
                    label="app.schedule.form.fields.date"
                    showTime={true}
                  />
                );
              }

              return null;
            }}
          />
          <TextField source="module" label="app.schedule.list.module" />
          <FunctionField
            source="handler"
            label={"app.schedule.list.handler"}
            render={(record: any) => (
              <span>
                {translate(`app.schedule.handlers.${record.handler}`)}
                {` (${record.handler})`}
              </span>
            )}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="app.schedule.show.tabs.payload">
          <FunctionField
            source="payload"
            render={(record: any) => {
              return <pre>{JSON.stringify(record.payload, null, 2)}</pre>;
            }}
          />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="app.schedule.show.tabs.runs">
          <ReferenceManyField
            reference={`schedule/tasks/${id}/runs`}
            target="id"
            filter={{}}
            sortable={false}
            emptyText={translate("app.schedule.form.emptyRuns")}
          >
            <StyledDatagrid
              expandSingle={true}
              bulkActionButtons={false}
              expand={<TaskRunPanel />}
              sort={{ field: "startedAt", order: "DESC" }}
              empty={<span>{translate("app.schedule.form.emptyRuns")}</span>}
            >
              <TextField source="id" label="app.schedule.form.fields.id" />
              <DateField
                source="startedAt"
                label="app.schedule.form.fields.startedAt"
                showTime={true}
              />
              <FunctionField
                source="finishedAt"
                label="app.schedule.form.fields.finishedAt"
                render={(record: any) => {
                  if (record.finishedAt) {
                    return <DateField source="finishedAt" showTime={true} />;
                  }

                  return <span>Not yet finished</span>;
                }}
              />
              <FunctionField
                source="status"
                label="app.schedule.form.fields.finishStatus"
                render={(record: { status: ETaskRunStatus }) => {
                  return (
                    <Chip
                      sx={{
                        ...taskRunColors[record.status],
                      }}
                      label={translate(
                        `app.schedule.runStatuses.${record.status}`,
                      )}
                    />
                  );
                }}
              />
              <ChipField
                source="requestId"
                label="app.schedule.form.fields.requestId"
                variant="outlined"
                sx={{ userSelect: "all" }}
              />
              <ChipField
                source="hostname"
                label="app.schedule.form.fields.hostname"
                variant="outlined"
                sx={{ userSelect: "all" }}
              />
            </StyledDatagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </StyledShow>
  );
};

export default TaskShow;
