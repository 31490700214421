import { ReactElement, useEffect, useMemo } from "react";
import {
  Loading,
  required,
  TextInput,
  useGetIdentity,
  useTranslate,
} from "react-admin";
import { useFormContext, useWatch } from "react-hook-form";
import { Typography } from "@mui/material";
import {
  StyledArraySelect,
  StyledDateInput,
  StyledSelect,
} from "../../../../themes/styles";
import { ConfigManager } from "../../../../constants/ConfigManager";
import { IIdentity } from "../../../../types/interfaces/identity.interface";

const CreateReportHandler = (): ReactElement => {
  const translate = useTranslate();
  const { setValue } = useFormContext();
  const { isLoading: userLoading, data: userData } =
    useGetIdentity() as unknown as {
      isLoading: boolean;
      data: IIdentity;
    };

  const periodWatch = useWatch<{ "payload.range.type": string }>({
    name: "payload.range.type",
  });

  const periodChoices = useMemo(
    () => [
      {
        id: "today",
        name: "app.parking.schedule.handlers.create_report.form.periods.today",
      },
      {
        id: "yesterday",
        name: "app.parking.schedule.handlers.create_report.form.periods.yesterday",
      },
      {
        id: "last7days",
        name: "app.parking.schedule.handlers.create_report.form.periods.last7days",
      },
      {
        id: "last30days",
        name: "app.parking.schedule.handlers.create_report.form.periods.last30days",
      },
      {
        id: "lastWeek",
        name: "app.parking.schedule.handlers.create_report.form.periods.lastWeek",
      },
      {
        id: "lastMonth",
        name: "app.parking.schedule.handlers.create_report.form.periods.lastMonth",
      },
      {
        id: "lastYear",
        name: "app.parking.schedule.handlers.create_report.form.periods.lastYear",
      },
      {
        id: "thisWeek",
        name: "app.parking.schedule.handlers.create_report.form.periods.thisWeek",
      },
      {
        id: "thisMonth",
        name: "app.parking.schedule.handlers.create_report.form.periods.thisMonth",
      },
      {
        id: "thisYear",
        name: "app.parking.schedule.handlers.create_report.form.periods.thisYear",
      },
      {
        id: "custom",
        name: "app.parking.schedule.handlers.create_report.form.periods.custom",
      },
    ],
    [],
  );

  const sheetsChoices = useMemo(
    () => [
      {
        id: "summary",
        name: "app.parking.schedule.handlers.create_report.form.sheets.summary",
      },
      {
        id: "customers",
        name: "app.parking.schedule.handlers.create_report.form.sheets.customers",
      },
      {
        id: "tickets",
        name: "app.parking.schedule.handlers.create_report.form.sheets.tickets",
      },
    ],
    [],
  );

  const formatChoices = useMemo(
    () => [
      {
        id: "xlsx",
        name: "XLSX",
      },
      {
        id: "csv",
        name: "CSV",
      },
    ],
    [],
  );

  const showCustomRange = useMemo(() => {
    return periodWatch === "custom";
  }, [periodWatch]);

  useEffect(() => {
    if (userData) {
      setValue("payload.userId", userData.id.toString());
    }
  }, [setValue, userData]);

  if (userLoading) {
    return <Loading />;
  }

  return (
    <>
      <TextInput
        source="payload.groupId"
        hidden
        sx={{ display: "none" }}
        defaultValue={ConfigManager.getInstance().getOrgId()}
      />
      <TextInput source="payload.userId" hidden sx={{ display: "none" }} />
      <StyledSelect
        source="payload.range.type"
        label="app.parking.schedule.handlers.create_report.labels.period"
        choices={periodChoices}
        validate={[required()]}
        defaultValue={"lastMonth"}
      />
      {showCustomRange && (
        <>
          <StyledDateInput
            source="payload.range.from"
            label="app.parking.schedule.handlers.create_report.labels.from"
            validate={[required()]}
          />
          <StyledDateInput
            source="payload.range.to"
            label="app.parking.schedule.handlers.create_report.labels.to"
            validate={[required()]}
          />
        </>
      )}
      <StyledArraySelect
        source="payload.sheets"
        label="app.parking.schedule.handlers.create_report.labels.sheets"
        choices={sheetsChoices}
        validate={[required()]}
        defaultValue={["summary"]}
      />
      <StyledSelect
        source="payload.format"
        label="app.parking.schedule.handlers.create_report.labels.format"
        choices={formatChoices}
        validate={[required()]}
        defaultValue={"xlsx"}
      />
      <Typography variant="caption" gutterBottom>
        {translate(
          "app.parking.schedule.handlers.create_report.form.email_info",
          { email: userData?.email },
        )}
      </Typography>
    </>
  );
};

export default CreateReportHandler;
