import ScheduleCreate from "./ScheduleCreate";
import ScheduleEdit from "./ScheduleEdit";
import ScheduleList from "./ScheduleList";
import ScheduleShow from "./ScheduleShow";

const scheduleResource = {
  create: ScheduleCreate,
  edit: ScheduleEdit,
  list: ScheduleList,
  show: ScheduleShow,
};

export default scheduleResource;
