import React from "react";
import { required } from "react-admin";
import { AllowedAttributeType } from "../../../../types/enums/ticket-attribute-type.enums";
import { ITicketAttributeOption } from "../../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";
import {
  StyledDateInput,
  StyledNumberInput,
  StyledRichTextInput,
  StyledTextInput,
} from "../../../../themes/styles";
import TicketAttributeSelectInput from "./TicketAttributeSelectInput";

export type IAttributeInputProps = {
  id: string;
  source: string;
  label: string;
  inputType: AllowedAttributeType;
  choices?: ITicketAttributeOption[];
  isRequired: boolean;
  isSavingNewValues?: boolean;
};

const AttributeInput = ({
  id,
  source,
  label,
  inputType,
  choices,
  isRequired,
  isSavingNewValues,
}: IAttributeInputProps) => {
  const components = [
    <StyledTextInput
      source={`${source}.id`}
      style={{ display: "none" }}
      defaultValue={id}
      validate={isRequired ? required() : []}
      hidden
    />,
  ];

  switch (inputType) {
    case AllowedAttributeType.SingleLineText:
      components.push(
        <StyledTextInput
          source={`${source}.value`}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          defaultValue={""}
          validate={isRequired ? required() : []}
        />,
      );
      break;
    case AllowedAttributeType.DateTime:
      components.push(
        <StyledDateInput
          source={`${source}.value`}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          defaultValue={""}
          validate={isRequired ? required() : []}
        />,
      );
      break;
    case AllowedAttributeType.Decimal:
    case AllowedAttributeType.Integer:
      components.push(
        <StyledNumberInput
          source={`${source}.value`}
          label={label}
          variant="outlined"
          style={{ width: "100%" }}
          defaultValue={""}
          validate={isRequired ? required() : []}
        />,
      );
      break;
    case AllowedAttributeType.MultiLineText:
      components.push(
        <StyledRichTextInput
          source={`${source}.value`}
          label={label}
          fullWidth
          type="outlined"
          defaultValue={""}
          style={{ width: "100%" }}
          validate={isRequired ? required() : []}
        />,
      );
      break;
    case AllowedAttributeType.SingleSelect:
      components.push(
        <TicketAttributeSelectInput
          id={id}
          source={`${source}.value`}
          label={label}
          choices={choices}
          isRequired={isRequired}
          isSavingNewValues={isSavingNewValues}
        />,
      );
      break;
    default:
      return null;
  }

  return <>{components}</>;
};

export default AttributeInput;
