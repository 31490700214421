export enum ETaskType {
  OneTime = "one-time",
  Recurring = "recurring",
}

export enum ECronField {
  Minute = "minute",
  Hour = "hour",
  DayOfMonth = "dayOfMonth",
  Month = "month",
  DayOfWeek = "dayOfWeek",
}

export enum ETaskRunStatus {
  Pending = "pending",
  Running = "running",
  Completed = "completed",
  Cancelled = "cancelled",
  Failed = "failed",
}
