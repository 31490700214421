import React, { useEffect, useState } from "react";
import {
  CreateButton,
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  ListToolbarProps,
  TextField,
  TopToolbar,
  useAuthenticated,
  useList,
  useListContext,
  useLocaleState,
} from "react-admin";
import { listTopFilter, StyledList } from "../../../themes/styles";
import SkeletonLoaderList from "../../../components/common/SkeletonLoaderList";
import { ITickerUser } from "../../../types/interfaces/helpdesk/ticket/ticket.interface";
import { sanitizeListRestProps } from "ra-core";
import CustomPagination from "../../../components/common/CustomPagination";
import { Chip } from "@mui/material";
import { reformatToAcceptableByListComponent } from "../../../utils/ticket/ticketsHelper";
import { ITicketAttributeOption } from "../../../types/interfaces/helpdesk/ticket/ticket-attribute-option.interface";

export interface ITicketGridRow {
  id: string;
  value: string | ITicketAttributeOption | undefined;
  name: string;
  createdAt: any;
  reporter: ITickerUser;
}

const TicketsListTopToolbar = (props: ListToolbarProps) => {
  const { className, ...rest } = props;

  return (
    <TopToolbar
      className={className}
      {...sanitizeListRestProps(rest)}
      sx={listTopFilter.topToolbar.rightFilters}
    >
      <CreateButton className="create-button" />
    </TopToolbar>
  );
};

const TicketsListPagination = () => {
  return (
    <CustomPagination
      rowsPerPageOptions={[10, 25, 100]}
      sx={listTopFilter.pagination}
    />
  );
};

const initialPagination = { page: 1, perPage: 10 };

const TicketsListView = () => {
  const [locale] = useLocaleState();
  const { data, page, perPage } = useListContext();
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState<ITicketGridRow[]>([]);

  const listContext = useList({
    data: list,
    sort: { field: "id", order: "DESC" },
    isLoading,
    ...initialPagination,
    perPage,
  });

  useEffect(() => {
    if (data) {
      setList(reformatToAcceptableByListComponent(data));
      setIsLoading(false);
    }
  }, [data, page, perPage]);

  return (
    <SkeletonLoaderList>
      <ListContextProvider value={listContext}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="title" label="app.helpdesk.title" />
          <FunctionField
            label="app.helpdesk.priority"
            color="primary"
            render={(record: any) => {
              if (record?.priority) {
                return (
                  <Chip
                    color="primary"
                    label={record?.priority?.value[locale]}
                    style={{ background: record?.priority?.style?.color }}
                  />
                );
              }
            }}
          />
          <FunctionField
            label="Status"
            color="primary"
            render={(record: any) => {
              if (record?.status) {
                return (
                  <Chip
                    color="primary"
                    label={record?.status?.value[locale]}
                    style={{ background: record?.status?.style?.color }}
                  />
                );
              }
            }}
          />
          <FunctionField
            label="app.helpdesk.reporter"
            render={(record: ITicketGridRow) =>
              record.reporter?.firstName &&
              `${record.reporter.firstName} ${record.reporter.lastName}`
            }
          />
          <DateField
            source="createdAt"
            showTime={true}
            label="app.helpdesk.createdAt"
          />
        </Datagrid>
      </ListContextProvider>
    </SkeletonLoaderList>
  );
};

const TicketsList = () => {
  useAuthenticated();

  return (
    <StyledList
      className="styled-list"
      actions={<TicketsListTopToolbar />}
      pagination={<TicketsListPagination />}
    >
      <TicketsListView />
    </StyledList>
  );
};

export default TicketsList;
