import { ETaskRunStatus } from "../../../types/enums/schedule.enums";

export interface ITaskRunColor {
  color: string;
  backgroundColor: string;
}

const taskRunColors: Record<ETaskRunStatus, ITaskRunColor> = {
  [ETaskRunStatus.Pending]: {
    color: "#FFA500",
    backgroundColor: "#FFF3E0",
  },
  [ETaskRunStatus.Running]: {
    color: "#FFA500",
    backgroundColor: "#FFF3E0",
  },
  [ETaskRunStatus.Completed]: {
    color: "#4CAF50",
    backgroundColor: "#E8F5E9",
  },
  [ETaskRunStatus.Cancelled]: {
    color: "#FFA500",
    backgroundColor: "#FFF3E0",
  },
  [ETaskRunStatus.Failed]: {
    color: "#F44336",
    backgroundColor: "#FFEBEE",
  },
};

export default taskRunColors;
